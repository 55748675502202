<template>
    <div class="container">
        <!-- 头部 -->
        <Header />
        <!-- 身体 -->
        <div class="main">
            <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                <img src="../../assets/imgs/xinwen.png" class="banner_img" alt />
                <div class="banner_box">

                    <!-- <div class="banner_box_r">
                        <div>新闻中心</div>
                        <div class="banner_box_r_txt">掌 / 握 / 实 / 时 / 资 / 讯 / 了 / 解 / 行 / 业 / 形 / 式</div>
                    </div> -->
                </div>
            </div>

            <!-- 主体 -->
            <div v-if="newDetails" class="main_box wow animate__slideInUp" data-wow-iteration="1"
                :data-wow-offset="height_top">
                <!-- 头部 -->
                <div class="main_box_top">
                    <div class="main_box_top_l wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
                        :data-wow-offset="height_top">
                        <div>
                            <div class="main_box_top_l_title">{{ newDetails.title }}</div>
                            <div class="main_box_top_l_txt">
                                <span>发布日期：{{ newDetails.created_at }}</span>
                                <span>浏览次数：{{ newDetails.pv }}</span>
                                <span>来源：{{ newDetails.administrator }}</span>
                            </div>
                        </div>
                        <div class="main_box_top_l_center" v-html="newDetails.content"></div>
                        <div class="main_box_top_l_btn">
                            <div>上一篇：
                                <span v-if="newDetails.prv.id" @click="prevs(newDetails.prv.id)">{{ newDetails.prv.title
                                }}</span>
                                <span v-else>暂无上一篇</span>
                            </div>
                            <div>下一篇：
                                <span v-if="newDetails.next.id" @click="nexts(newDetails.next.id)">{{ newDetails.next.title
                                }}</span>
                                <span v-else>暂无下一篇</span>
                            </div>
                        </div>
                    </div>
                    <div ref="banner" class="main_box_top_r wow animate__slideInUp" data-wow-delay="0.2s"
                        data-wow-iteration="1" :data-wow-offset="height_top">
                        <div class="main_box_top_r_title">
                            <span></span>
                            推荐新闻
                        </div>
                        <div class="main_box_top_Box" v-for="(item, index) in hotNewsList" :key="index"
                            @click="toDetails(item.id)">
                            <div class="img_box">
                                <img :src="item.image" class="main_box_top_Box_img" alt="">
                            </div>
                            <div class="main_box_top_Box_btn">
                                <div class="main_box_top_Box_btn_title">{{ item.title }}</div>
                                <div class="main_box_top_Box_btn_txt">{{ item.summary }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- 底部 -->
        <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
    </div>
</template>
  
<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import storage from "@/utils/storage";
import { articleinfo10, articlelist10recommend } from "@/api/axios/axios.api";

import { WOW } from "wowjs";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            height_top: storage.height_top,
            tabIndex: '',
            isActive: 1,
            id: '',
            newDetails: null,
            hotNewsList: [],
        };
    },
    methods: {
        // 上一页
        prevs(id) {
            this.id = id
            this.getDetails()
            window.scrollTo(0, 0)
        },
        // 下一页
        nexts(id) {
            this.id = id
            this.getDetails()
            window.scrollTo(0, 0)
        },
        // 详情
        toDetails(id) {
            this.id = id
            this.getDetails()
            window.scrollTo(0, 0)
        },
        // 获取详情
        async getDetails() {
            let res = await articleinfo10(this.id)
            this.newDetails = res.data;
            this.newDetails.content = res.data.content.replace(/<img/gi, "<img style=max-width:100%;height:auto");
            this.newDetails.content = res.data.content.replace(
                /<div/gi,
                "<div style=max-width:100%;height:auto"
            );
        },
        // 获取推荐
        async getHotNew() {
            let res = await articlelist10recommend()
            this.hotNewsList = res.data.list;
        },
    },
    created() { },
    mounted() {

        this.id = this.$route.query.id
        this.getDetails()
        this.getHotNew()

        this.$nextTick(() => {
            let wow = new WOW({
                live: true,
            });
            wow.init();

        });

    },
    watch: {

    },
};
</script>
  
<style lang="less" scoped>
@media screen and (min-width : 1400px) {
    .banner {
        color: #fff;
        // height: 358px;

        .banner_img {
            // position: absolute;
            // height: 358px;
            width: 100%;
        }

        .banner_box {
            position: relative;
            padding: 0 10%;
            display: flex;

            .banner_box_r {
                position: absolute;
                top: 50px;
                left: calc(50% - 232px);
                font-size: 40px;
                text-align: center;
                line-height: 48px;
                font-weight: bold;

                .banner_box_r_txt {
                    font-size: 20px;
                    font-weight: normal;
                }
            }
        }
    }

    // 身体
    .main_box {
        position: relative;
        margin-top: -84px;
        padding: 0 10%;
        padding-bottom: 42px;

        // 头部
        .main_box_top {
            // margin: 0 6%;
            // padding: 40px;
            box-sizing: border-box;
            display: flex;

            .main_box_top_l {
                // width: 540px;
                font-size: 14px;
                width: 70%;
                padding: 0 30px;
                padding-top: 50px;
                // margin-right: 3%;
                box-sizing: border-box;
                background-color: #fff;
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                .main_box_top_l_title {
                    font-size: 28px;
                    font-weight: bold;
                    line-height: 40px;
                    color: #333333;
                    margin-bottom: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    text-align: center;

                }

                .main_box_top_l_txt {
                    color: #999999;
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    text-align: center;

                    span {
                        margin: 0 30px;
                    }

                    border-bottom: 1px solid #f5f5f5;
                }

                // .main_box_top_l_center {
                //     flex: 1
                // }

                .main_box_top_l_btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #333333;
                    padding: 40px 0;
                    margin-top: 40px;
                    border-top: 1px solid #f5f5f5;

                    div {
                        max-width: 48%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        cursor: pointer;
                    }

                    div:hover span {
                        color: #000;
                    }
                }
            }

            .main_box_top_r {
                background-color: #FAFAFA;
                flex: 1;
                height: 100%;
                color: #999;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .main_box_top_r_title {
                    color: #333333;
                    padding: 26px 0;
                    font-size: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    display: flex;
                    align-items: center;

                    span {
                        display: inline-block;
                        width: 5px;
                        height: 90%;
                        background: #000000;
                        position: relative;
                        top: 3px;
                        margin-right: 20px;
                    }
                }

                .main_box_top_Box {
                    margin: 0 8%;
                    margin-bottom: 10px;
                    padding: 1.5%;
                    background-color: #fff;
                    transition: all 0.5s;
                    cursor: pointer;

                    .img_box {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;

                        .main_box_top_Box_img {
                            width: 100%;
                            height: 100%;
                            transition: all 0.5s;
                        }
                    }

                    .main_box_top_Box_btn {
                        .main_box_top_Box_btn_title {
                            font-size: 16px;
                            font-weight: bold;
                            color: #333333;
                            line-height: 40px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                        }

                        .main_box_top_Box_btn_txt {
                            font-size: 14px;
                            color: #999999;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                        }
                    }
                }

                .main_box_top_Box:hover {
                    transition: all 0.5s;
                    box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }

                .main_box_top_Box:hover .main_box_top_Box_img {
                    transform: scale(1.2);
                    transition: all 0.5s;
                }
            }
        }

    }
}

@media screen and (max-width : 1399px) {
    .banner {
        color: #fff;
        // height: 358px;
        min-width: 1300px;

        .banner_img {
            // position: absolute;
            // height: 358px;
            width: 100%;
        }

        .banner_box {
            position: relative;
            padding: 0 20px;
            display: flex;

            .banner_box_r {
                position: absolute;
                top: 50px;
                left: calc(50% - 232px);
                font-size: 40px;
                text-align: center;
                line-height: 48px;
                font-weight: bold;

                .banner_box_r_txt {
                    font-size: 20px;
                    font-weight: normal;
                }
            }
        }
    }

    // 身体
    .main_box {
        position: relative;
        margin-top: -84px;
        padding: 0 20px;
        padding-bottom: 42px;
        min-width: 1300px;

        // 头部
        .main_box_top {
            // margin: 0 6%;
            // padding: 40px;
            box-sizing: border-box;
            display: flex;

            .main_box_top_l {
                // width: 540px;
                font-size: 14px;
                width: 70%;
                padding: 0 30px;
                padding-top: 50px;
                // margin-right: 3%;
                box-sizing: border-box;
                background-color: #fff;
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                .main_box_top_l_title {
                    font-size: 28px;
                    font-weight: bold;
                    line-height: 40px;
                    color: #333333;
                    margin-bottom: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    text-align: center;

                }

                .main_box_top_l_txt {
                    color: #999999;
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    text-align: center;

                    span {
                        margin: 0 30px;
                    }

                    border-bottom: 1px solid #f5f5f5;
                }

                // .main_box_top_l_center {
                //     flex: 1
                // }

                .main_box_top_l_btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #333333;
                    padding: 40px 0;
                    margin-top: 40px;
                    border-top: 1px solid #f5f5f5;

                    div {
                        max-width: 48%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        cursor: pointer;
                    }

                    div:hover span {
                        color: #000;
                    }
                }
            }

            .main_box_top_r {
                background-color: #FAFAFA;
                flex: 1;
                height: 100%;
                color: #999;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .main_box_top_r_title {
                    color: #333333;
                    padding: 26px 0;
                    font-size: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    display: flex;
                    align-items: center;

                    span {
                        display: inline-block;
                        width: 5px;
                        height: 90%;
                        background: #000000;
                        position: relative;
                        top: 3px;
                        margin-right: 20px;
                    }
                }

                .main_box_top_Box {
                    margin: 0 8%;
                    margin-bottom: 10px;
                    padding: 1.5%;
                    background-color: #fff;
                    transition: all 0.5s;
                    cursor: pointer;

                    .img_box {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;

                        .main_box_top_Box_img {
                            width: 100%;
                            height: 100%;
                            transition: all 0.5s;
                        }
                    }

                    .main_box_top_Box_btn {
                        .main_box_top_Box_btn_title {
                            font-size: 16px;
                            font-weight: bold;
                            color: #333333;
                            line-height: 40px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                        }

                        .main_box_top_Box_btn_txt {
                            font-size: 14px;
                            color: #999999;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                        }
                    }
                }

                .main_box_top_Box:hover {
                    transition: all 0.5s;
                    box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }

                .main_box_top_Box:hover .main_box_top_Box_img {
                    transform: scale(1.2);
                    transition: all 0.5s;
                }
            }
        }

    }
}
</style>
  